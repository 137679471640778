// Fonts
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,500&display=swap");

@import 'bootstrap/scss/functions';

// User Variables
@import "user-variables";

// theme variables
@import "theme/variables";
@import "theme/variables-dark";

// theme utilitiles
@import "theme/utilities";

// Bootstrap
@import 'bootstrap/scss/bootstrap';

@import 'bootstrap-icons/font/bootstrap-icons.css';

// Theme
@import "theme/theme";

// User
@import "user";
