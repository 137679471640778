//
// user.scss
// Use this to write your custom SCSS
//

.success-circle {
    width: 100px; /* Adjust size as needed */
    height: 100px; /* Adjust size as needed */
    background-color: green; /* Success color */
    border-radius: 50%; /* Make it a circle */
    color: white; /* Checkmark color */
}

/* Adjust the size of the checkmark if necessary */
.success-circle .bi-check-lg {
    font-size: 2rem; /* Larger checkmark */
}

.grecaptcha-badge {
    visibility: hidden;
    visibility: hidden;
}
