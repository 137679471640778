//
// user-variables.scss
// Use this to overwrite Bootstrap and Block variables
//
// Example of a variable override to change Block primary color
// Remove the "//" to comment it in and see it in action!
// $primary: #000;

$primary: #3F5A97;
$secondary: #1e2022;
$success: #50ba40;
$info: #29abe0;
$warning: #ff9f2c;
$danger: #d9534f;
$dark: #132144;
$light: #f3f8ff;
